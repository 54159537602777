import { useRef, useEffect } from "react";

export function useDidUpdateEffect(func: () => void, dependencies: [any]) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) func();
    else didMountRef.current = true;
  }, dependencies);
}
