import * as React from "react";
import { observer } from "mobx-react";

import {
  UitkExperimentalButtonTabs,
  UitkTab,
  UitkTabsType,
  UitkTabAnchorType,
} from "uitk-react-experimental-button-tabs";

import { UitkPrimaryButton } from "@egds/react-core/button";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { UitkLayoutConditionalGridSpan } from "@egds/react-core/layout-grid";
import { UitkSheet, UitkSheetTransition } from "@egds/react-core/sheet";
import { UitkSpacing } from "@egds/react-core/spacing";
import { liveAnnounce, Level } from "@egds/react-core/utils";
import { useLocalization } from "@shared-ui/localization-context";
import { useRefTargetScroll, useRefContext } from "@shared-ui/ref-context";
import { Viewport, ViewSmall, ViewMedium, ViewLarge } from "@shared-ui/viewport-context";

import { Views, WizardFlightPWA } from "components/flexComponents/WizardFlightPWA/WizardFlightPWA";
import {
  FHCHiddenSearchData,
  FlightHiddenSearchData,
  locKeys,
  Multicity,
  Oneway,
  Roundtrip,
} from "components/flexComponents/WizardFlightPWA/components";
import {
  FlightHiddenSearchDataProps,
  WizardFlightPWAViewProps,
  WizardFlightPWAFlexModuleResult,
} from "components/flexComponents/WizardFlightPWA/typings";

import { LobTitle } from "components/shared/LobTitle/LobTitle";
import { LobSubtitle } from "components/shared/LobSubtitle/LobSubtitle";
import { PreferredAirlineInput } from "components/shared/PreferredAirlineInput/PreferredAirlineInput";
import { PreferredClassInput } from "components/shared/PreferredClassInput/PreferredClassInput";
import { StackedSticky, useStickyElement } from "components/shared/StackedSticky/StackedSticky";
import { SubmitRow } from "components/shared/StorefrontWizard/SubmitRow/SubmitRow";
import { Travelers } from "components/shared/Travelers/Travelers";
import { TravelersTriggerTypes } from "components/shared/TravelersField/typings";

import { getFmId } from "stores/ExperienceTemplateStore";
import { FlightType } from "stores/wizard/state/typings";
import { useDidUpdateEffect } from "utils/hooks/useDidUpdateEffect";
import {
  checkIfCurrentLastLegDateUpdated,
  triggerFlightsPredictiveSearchURL,
} from "utils/FlightsPredictiveSearchUtils";

enum ViewSize {
  SMALL = "small",
  MEDIUM = "medium",
}

const MobileCTA = ({
  view,
  ctaVisible,
  onClickScrollToWizard,
  displayText,
}: {
  view: ViewSize;
  ctaVisible: boolean;
  onClickScrollToWizard: () => void;
  displayText: string;
}) => {
  if (view !== ViewSize.SMALL) {
    return null;
  }

  return (
    <UitkSheetTransition isVisible={ctaVisible}>
      <UitkSheet className="mobileStickyWizardCTA" isVisible={ctaVisible} triggerRef={null}>
        <UitkSpacing padding={{ block: "four", inline: "three" }}>
          <div>
            <UitkLayoutFlex justifyContent="space-between" wrap="nowrap" alignItems="center" space="two">
              <>
                <UitkLayoutFlexItem>
                  <UitkPrimaryButton data-testid="MobileStickyCTA" onClick={onClickScrollToWizard} isFullWidth>
                    {displayText}
                  </UitkPrimaryButton>
                </UitkLayoutFlexItem>
              </>
            </UitkLayoutFlex>
          </div>
        </UitkSpacing>
      </UitkSheet>
    </UitkSheetTransition>
  );
};

export const WizardFlightPWALPView: React.FunctionComponent<WizardFlightPWAViewProps> = observer((props) => {
  const {
    flightWizardState,
    globalWizardState,
    flightWizardState: { config: flightConfig },
  } = props.wizardState;
  const { flexModuleModelStore, templateComponent } = props;
  const {
    metadata: { id },
  } = templateComponent;
  const model = flexModuleModelStore.getModel(id) as WizardFlightPWAFlexModuleResult | null;
  if (!model) {
    return null;
  }

  const { formatText } = useLocalization();
  const { registerTarget } = useRefContext();
  const { scrollTo } = useRefTargetScroll();
  const [isCTASticky, setCTASticky] = React.useState(false);
  const stickyWizardRef = React.useRef<HTMLDivElement>(null);
  const mobileWizardWrapper = registerTarget<HTMLDivElement>("WizardWrapperSticky");
  const {
    flightClassCode,
    listOfAdditionalLegs,
    subLOBState,
    nonHotelTravelersMetadata,
    multiCityFlightLegURL,
    lastAdditionalLegDate,
  } = flightWizardState;

  flightWizardState.overrideConfig(() => {
    flightWizardState.config.enableNonStopFlight = true;
  });

  if (listOfAdditionalLegs.length > 1) {
    liveAnnounce(formatText(locKeys.multiCityFlightLegLabel, listOfAdditionalLegs.length + 1), Level.POLITE);
  }

  const onClickScrollToWizard = (): void => {
    scrollTo("WizardWrapperSticky", []);
  };

  const flightHiddenSearchDataProps: FlightHiddenSearchDataProps = {
    flightType: "on",
    flightClass: WizardFlightPWA.getFlightClassCodeForURL(flightClassCode),
    mode: "search",
    trip: subLOBState,
    leg1: WizardFlightPWA.getLegURLFragment(flightWizardState, flightConfig)[0],
    leg2: WizardFlightPWA.getLegURLFragment(flightWizardState, flightConfig)[1],
    flightAirline: globalWizardState.flightAirline,
    flightWizardState: props.wizardState.flightWizardState,
    maxhops: props.wizardState.flightWizardState.isNonStopFlightChecked ? "maxhops:0" : "",
    globalWizardState,
  };

  const handleScroll = () => {
    if (mobileWizardWrapper && mobileWizardWrapper.current) {
      const ctaPosition = mobileWizardWrapper.current.getBoundingClientRect().bottom < 0;
      setCTASticky(ctaPosition);
    } else {
      setCTASticky(false);
    }
  };
  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, []);

  useDidUpdateEffect(() => {
    if (subLOBState === FlightType.ROUND_TRIP) {
      triggerFlightsPredictiveSearchURL({
        flightHiddenSearchDataProps,
        multiCityFlightLegURL,
        travelersMetadata: nonHotelTravelersMetadata,
      });
    }
  }, [flightWizardState.date.end]);

  useDidUpdateEffect(() => {
    if (subLOBState === FlightType.ONE_WAY) {
      triggerFlightsPredictiveSearchURL({
        flightHiddenSearchDataProps,
        multiCityFlightLegURL,
        travelersMetadata: nonHotelTravelersMetadata,
      });
    }
  }, [flightWizardState.date.start]);

  useDidUpdateEffect(() => {
    if (
      checkIfCurrentLastLegDateUpdated(
        subLOBState,
        globalWizardState.getDateFromConfig(flightConfig.date).start,
        lastAdditionalLegDate
      )
    ) {
      triggerFlightsPredictiveSearchURL({
        flightHiddenSearchDataProps,
        multiCityFlightLegURL,
        travelersMetadata: nonHotelTravelersMetadata,
      });
    }
  }, [lastAdditionalLegDate]);

  const stickyWizardEnabled =
    !(flightWizardState.isAddAHotelChecked || flightWizardState.isAddACarChecked) &&
    flightWizardState.subLOBState !== FlightType.MULTI_CITY;

  return (
    <Viewport>
      <ViewSmall>
        <div className="WizardFlightPWALP-Wrapper" id="WizardWrapperSticky" ref={mobileWizardWrapper}>
          <Form flightHiddenSearchDataProps={flightHiddenSearchDataProps} options={model} {...props} />
          <MobileCTA
            view={ViewSize.SMALL}
            ctaVisible={isCTASticky}
            onClickScrollToWizard={onClickScrollToWizard}
            displayText={formatText("wizard.mobile.label.flight")}
          />
        </div>
      </ViewSmall>
      <ViewMedium>
        <div className="WizardFlightPWALP-Wrapper" id="WizardWrapperSticky" ref={stickyWizardRef}>
          {stickyWizardEnabled ? (
            <StackedSticky trigger="bottom" padded>
              <Form flightHiddenSearchDataProps={flightHiddenSearchDataProps} options={model} {...props} />
            </StackedSticky>
          ) : (
            <Form flightHiddenSearchDataProps={flightHiddenSearchDataProps} options={model} {...props} />
          )}
        </div>
      </ViewMedium>
    </Viewport>
  );
});

const Form: React.FC<
  WizardFlightPWAViewProps & {
    flightHiddenSearchDataProps: FlightHiddenSearchDataProps;
    options: WizardFlightPWAFlexModuleResult;
  }
> = (props) => {
  const { wizardState, flightHiddenSearchDataProps, onSelectFlightType, options, templateComponent } = props;

  const { isSticky } = useStickyElement();
  const { formatText } = useLocalization();

  const { flightWizardState, globalWizardState } = wizardState;
  const { config: flightConfig, subLOBState } = flightWizardState;

  const isInitialMount = React.useRef(true);
  const showWhenNotSticky = !isSticky;
  const wizardFormTestId = isSticky ? "WizardFlightPWALP-sticky" : "WizardFlightPWALP";

  const currentTab = subLOBState; // tab state is calculated based on subLOB
  const tabIdPrefix = "wizard-flight-tab-";

  const referrerId =
    currentTab !== FlightType.MULTI_CITY ? flightConfig.referrerId : flightConfig.multiCityFlight.referrerId;

  const { pageHeading, pageSubHeadline } = props.compositionStore.composition!;

  const titleFromComposition = (options.titleFromPageHeader && pageHeading) || undefined;
  const subTitleFromComposition = (options.subTitleFromPageSubHeader && pageSubHeadline) || undefined;

  const travelersColSpan: UitkLayoutConditionalGridSpan = { small: 2, medium: 2, large: 2 };
  const preferredClassColSpan: UitkLayoutConditionalGridSpan = { small: 3, medium: 2, large: 2 };
  const { config, isAddAHotelChecked, isAddACarChecked } = flightWizardState;
  const travelerConfig =
    config.enableAddHotel && isAddAHotelChecked
      ? config.travelersWithHotel
      : config.enableAddCar && isAddACarChecked
      ? config.travelersWithCar
      : config.travelers;
  const isCenteredButton = templateComponent.config.isCenteredSubmitButton;
  const isUsingUXTweaks = templateComponent.config.view === Views.LANDING_PAGE_UX_TWEAKS;

  /**
   * Value used if no rehydratation(JS disabled)
   */
  const { noRehydratationValues } = flightConfig.travelers;

  React.useLayoutEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    }
  });

  /**
   * Builds the Travelers and PreferredClassInput
   * as a prop to be sent to UitkExperimentalButtonTabs
   * to display them next to the UitkTabs, at the right
   */
  const items = (
    <UitkLayoutFlex alignItems="center" space="six">
      <UitkLayoutFlexItem>
        <Travelers
          lobState={flightWizardState}
          config={travelerConfig}
          colSpan={travelersColSpan}
          noRehydratationValues={noRehydratationValues}
          triggerType={TravelersTriggerTypes.LINK_BUTTON}
        />
      </UitkLayoutFlexItem>
      <UitkLayoutFlexItem>
        <PreferredClassInput
          colSpan={preferredClassColSpan}
          onSelectPreferredClassCode={props.onSelectPreferredClassCode}
          wizardState={props.wizardState}
        />
      </UitkLayoutFlexItem>
      <UitkLayoutFlexItem>
        <PreferredAirlineInput
          onSelectPreferredAirline={props.onSelectPreferredAirline}
          wizardState={props.wizardState}
        />
      </UitkLayoutFlexItem>
    </UitkLayoutFlex>
  );
  const noNavigationItems = () => (
    <Viewport>
      {isUsingUXTweaks ? (
        <ViewMedium>
          <UitkSpacing padding={{ inlinestart: "six" }}>{items}</UitkSpacing>
        </ViewMedium>
      ) : (
        <ViewLarge>
          <UitkSpacing padding={{ inline: "six" }}>{items}</UitkSpacing>
        </ViewLarge>
      )}
    </Viewport>
  );

  return (
    <form
      noValidate
      className="WizardFlightPWALP"
      action={
        flightWizardState.isAddAHotelChecked || flightWizardState.isAddACarChecked
          ? "/flexibleshopping"
          : "/Flights-Search"
      }
      onSubmit={flightWizardState.submit}
      id={flightConfig.elementId}
      data-fm={getFmId(templateComponent)}
      data-fm-title-id={templateComponent.config.fmTitleId}
      data-testid={wizardFormTestId}
    >
      {flightWizardState.isAddAHotelChecked || flightWizardState.isAddACarChecked ? (
        <FHCHiddenSearchData flightWizardState={flightWizardState} />
      ) : (
        <FlightHiddenSearchData {...flightHiddenSearchDataProps} />
      )}

      {showWhenNotSticky && (
        <>
          <LobTitle
            lobState={flightWizardState}
            customTitle={titleFromComposition}
            mobileHeadingSize={templateComponent.config.mobileHeadingSize}
          />
          <LobSubtitle lobState={flightWizardState} customSubTitle={subTitleFromComposition} />
        </>
      )}
      {/* Each tab represents a flights sub LOB (roundtrip, one way and multicity) */}
      <UitkSpacing
        margin={
          isUsingUXTweaks
            ? { small: { blockstart: "three" }, medium: { blockstart: "four" }, large: { blockstart: "six" } }
            : { blockstart: "three" }
        }
      >
        <div>
          <UitkExperimentalButtonTabs
            className="flightTypeTabs"
            selectedTab={currentTab}
            onTabSelect={onSelectFlightType}
            tabsType={UitkTabsType.NATURAL}
            tabAnchorType={UitkTabAnchorType.BUTTON}
            noNavigationItems={noNavigationItems}
            data-testid={currentTab}
          >
            <UitkTab
              name={FlightType.ROUND_TRIP}
              displayName={formatText(locKeys.flightRoundTripText)}
              targetURI={`?flightType=${FlightType.ROUND_TRIP}`}
              id={`${tabIdPrefix}${FlightType.ROUND_TRIP}`}
            >
              {currentTab === FlightType.ROUND_TRIP && (
                <Roundtrip
                  flightConfig={flightConfig}
                  flightWizardState={flightWizardState}
                  formProps={props}
                  isInitialMount={isInitialMount}
                  isSticky={isSticky}
                  isUsingUXTweaks={isUsingUXTweaks}
                />
              )}
            </UitkTab>

            <UitkTab
              name={FlightType.ONE_WAY}
              displayName={formatText(locKeys.flightOneWayText)}
              targetURI={`?flightType=${FlightType.ONE_WAY}`}
              id={`${tabIdPrefix}${FlightType.ONE_WAY}`}
            >
              {currentTab === FlightType.ONE_WAY && (
                <Oneway
                  flightConfig={flightConfig}
                  flightWizardState={flightWizardState}
                  formProps={props}
                  isInitialMount={isInitialMount}
                  isSticky={isSticky}
                  isUsingUXTweaks={isUsingUXTweaks}
                />
              )}
            </UitkTab>

            <UitkTab
              name={FlightType.MULTI_CITY}
              displayName={formatText(locKeys.flightMultiCityText)}
              targetURI={`?flightType=${FlightType.MULTI_CITY}`}
              id={`${tabIdPrefix}${FlightType.MULTI_CITY}`}
              className={`${isSticky ? "is-hidden" : ""}`}
            >
              {currentTab === FlightType.MULTI_CITY && (
                <Multicity
                  flightConfig={flightConfig}
                  flightWizardState={flightWizardState}
                  formProps={props}
                  isUsingUXTweaks={isUsingUXTweaks}
                />
              )}
            </UitkTab>
          </UitkExperimentalButtonTabs>
        </div>
      </UitkSpacing>
      <Viewport>
        <ViewSmall>
          <>
            <UitkSpacing padding={{ blockstart: isUsingUXTweaks ? "one" : "four" }}>
              <UitkLayoutFlex alignItems="center" space="six">
                <UitkLayoutFlexItem>
                  <div>
                    <Travelers
                      lobState={flightWizardState}
                      config={travelerConfig}
                      colSpan={travelersColSpan}
                      noRehydratationValues={noRehydratationValues}
                      triggerType={TravelersTriggerTypes.LINK_BUTTON}
                    />
                  </div>
                </UitkLayoutFlexItem>
                <UitkLayoutFlexItem>
                  <PreferredClassInput
                    colSpan={preferredClassColSpan}
                    onSelectPreferredClassCode={props.onSelectPreferredClassCode}
                    wizardState={props.wizardState}
                  />
                </UitkLayoutFlexItem>
                <UitkLayoutFlexItem>
                  <PreferredAirlineInput
                    onSelectPreferredAirline={props.onSelectPreferredAirline}
                    wizardState={props.wizardState}
                  />
                </UitkLayoutFlexItem>
              </UitkLayoutFlex>
            </UitkSpacing>
            {showWhenNotSticky && isUsingUXTweaks && (
              <SubmitRow
                lobState={flightWizardState}
                rfrr={referrerId}
                globalConfig={globalWizardState.config}
                isCenteredButton={isCenteredButton}
                submitButtonIconName="arrow_forward"
              />
            )}
          </>
        </ViewSmall>
        {isUsingUXTweaks ? <ViewMedium /> : <></>}
        <ViewLarge />
      </Viewport>
      {showWhenNotSticky && !isUsingUXTweaks && (
        <SubmitRow
          lobState={flightWizardState}
          rfrr={referrerId}
          globalConfig={globalWizardState.config}
          isCenteredButton={isCenteredButton}
        />
      )}
    </form>
  );
};
