import * as React from "react";
import { observer } from "mobx-react";
import { locKeys } from "components/flexComponents/WizardFlightPWA/components/l10n";
import { UitkCheckbox } from "@egds/react-core/checkbox";
import { FlightAddHotelProps } from "components/flexComponents/WizardFlightPWA/typings";
import { useLocalization } from "@shared-ui/localization-context";
import { UitkLayoutFlexItem } from "@egds/react-core/layout-flex";

export const FlightAddHotelCheckbox = observer((props: FlightAddHotelProps) => {
  const { formatText } = useLocalization();

  return (
    <UitkLayoutFlexItem>
      <UitkCheckbox
        checked={props.flightWizardState.isAddAHotelChecked}
        id="add-hotel-checkbox"
        onChange={props.flightWizardState.toggleAddAHotelCheckbox}
      >
        {formatText(locKeys.addHotelLabel)}
      </UitkCheckbox>
    </UitkLayoutFlexItem>
  );
});
