import * as React from "react";
import { useLocation } from "react-router-dom";
import { observer } from "mobx-react";

import { useLocalization } from "@shared-ui/localization-context";
import { ViewLarge, Viewport, ViewSmall } from "@shared-ui/viewport-context";
import {
  UitkExperimentalButtonTabs,
  UitkTab,
  UitkTabAnchorType,
  UitkTabsType,
} from "uitk-react-experimental-button-tabs";
import { Level, liveAnnounce } from "@egds/react-core/utils";
import { UitkSpacing } from "@egds/react-core/spacing";

import { LobTitle } from "components/shared/LobTitle/LobTitle";
import { LobSubtitle } from "components/shared/LobSubtitle/LobSubtitle";
import { SubmitRow } from "components/shared/StorefrontWizard/SubmitRow/SubmitRow";
import { FlightType } from "stores/wizard/state/typings";

import { MulticityFlight } from "../components/MulticityFlight";
import { OnewayFlight } from "../components/OnewayFlight";
import { RoundtripFlight } from "../components/RoundtripFlight";

import { WizardFlightPWA } from "../WizardFlightPWA";
import { FHCHiddenSearchData } from "../components/FHCHiddenSearchData";
import { FlightHiddenSearchData } from "../components/FlightHiddenSearchData";
import { locKeys } from "../components/l10n";
import { NavigationItems } from "../components/NavigationItems";

import { FlightHiddenSearchDataProps, WizardFlightPWAViewProps, WizardFlightPWAFlexModuleResult } from "../typings";
import { useDidUpdateEffect } from "src/utils/hooks/useDidUpdateEffect";
import {
  checkIfCurrentLastLegDateUpdated,
  triggerFlightsPredictiveSearchURL,
} from "src/utils/FlightsPredictiveSearchUtils";
import { Action } from "src/components/utility/analytics/FlexAnalyticsUtils";
import { getFmId } from "src/stores/ExperienceTemplateStore";

export const WizardFlightPWAView: React.FunctionComponent<WizardFlightPWAViewProps> = observer((props) => {
  const { page, flexModuleModelStore, templateComponent } = props;
  const {
    globalWizardState,
    flightWizardState,
    flightWizardState: { config: flightConfig },
  } = props.wizardState;

  const { formatText } = useLocalization();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const tabIdPrefix = "wizard-flight-tab-";
  const {
    flightClassCode,
    listOfAdditionalLegs,
    subLOBState,
    nonHotelTravelersMetadata,
    multiCityFlightLegURL,
    lastAdditionalLegDate,
  } = flightWizardState;
  const currentTab = subLOBState; // tab state is calculated based on subLOB

  if (listOfAdditionalLegs.length > 1) {
    liveAnnounce(formatText(locKeys.multiCityFlightLegLabel, listOfAdditionalLegs.length + 1), Level.POLITE);
  }

  const flightHiddenSearchDataProps: FlightHiddenSearchDataProps = {
    flightType: "on",
    flightClass: WizardFlightPWA.getFlightClassCodeForURL(flightClassCode),
    flightAirline: globalWizardState.flightAirline,
    mode: "search",
    trip: subLOBState,
    leg1: WizardFlightPWA.getLegURLFragment(flightWizardState, flightConfig)[0],
    leg2: WizardFlightPWA.getLegURLFragment(flightWizardState, flightConfig)[1],
    queryParams,
    flightWizardState: props.wizardState.flightWizardState,
    globalWizardState,
  };

  const { halfWizard } = globalWizardState.config;

  /**
   * Set the new tab to display and update global state
   */
  const handleTabChange = (flightType: FlightType) => {
    props.onSelectFlightType(flightType);
  };

  /**
   * Builds the Travelers and PreferredClassInput
   * as a prop to be sent to UitkExperimentalButtonTabs
   * to display them next to the UitkTabs, at the right
   */
  const noNavigationItems = () => (
    <Viewport>
      <ViewLarge>
        <NavigationItems
          wizardState={props.wizardState}
          onSelectPreferredClassCode={props.onSelectPreferredClassCode}
          onSelectPreferredAirline={props.onSelectPreferredAirline}
        />
      </ViewLarge>
    </Viewport>
  );

  const {
    metadata: { id },
    config: { fmTitleId },
  } = templateComponent;
  const model = flexModuleModelStore.getModel(id) as WizardFlightPWAFlexModuleResult | null;
  if (!model) {
    return null;
  }

  const referrerId =
    currentTab !== FlightType.MULTI_CITY ? flightConfig.referrerId : flightConfig.multiCityFlight.referrerId;

  const pageName = page ? page.pageName : "unknown";
  const moduleName = halfWizard ? "HalfBiasedWizard" : "FullBiasedWizard";
  const linkName = "FlightSearch";
  const sectionName = "SQM";
  const rfrrid = `${pageName}.${moduleName}.${linkName}.${sectionName}`;

  const extraTracking: any = {
    schemaName: "referrer",
    messageContent: {
      referrerId: rfrrid,
      eventType: Action.CLICK,
    },
  };

  const { pageHeading, pageSubHeadline } = props.compositionStore.composition!;

  const titleFromComposition = (model.titleFromPageHeader && pageHeading) || undefined;
  const subTitleFromComposition = (model.subTitleFromPageSubHeader && pageSubHeadline) || undefined;
  const fmId = getFmId(templateComponent);

  const smallViewNavigationItems = (
    <div>
      <Viewport>
        <ViewSmall>
          <NavigationItems
            wizardState={props.wizardState}
            onSelectPreferredClassCode={props.onSelectPreferredClassCode}
            onSelectPreferredAirline={props.onSelectPreferredAirline}
          />
        </ViewSmall>
        <ViewLarge />
      </Viewport>
    </div>
  );

  useDidUpdateEffect(() => {
    if (subLOBState === FlightType.ROUND_TRIP) {
      triggerFlightsPredictiveSearchURL({
        flightHiddenSearchDataProps,
        multiCityFlightLegURL,
        travelersMetadata: nonHotelTravelersMetadata,
      });
    }
  }, [flightWizardState.date.end]);

  useDidUpdateEffect(() => {
    if (subLOBState === FlightType.ONE_WAY) {
      triggerFlightsPredictiveSearchURL({
        flightHiddenSearchDataProps,
        multiCityFlightLegURL,
        travelersMetadata: nonHotelTravelersMetadata,
      });
    }
  }, [flightWizardState.date.start]);

  useDidUpdateEffect(() => {
    if (
      checkIfCurrentLastLegDateUpdated(
        subLOBState,
        globalWizardState.getDateFromConfig(flightConfig.date).start,
        lastAdditionalLegDate
      )
    ) {
      triggerFlightsPredictiveSearchURL({
        flightHiddenSearchDataProps,
        multiCityFlightLegURL,
        travelersMetadata: nonHotelTravelersMetadata,
      });
    }
  }, [lastAdditionalLegDate]);

  return (
    <form
      noValidate
      className="WizardFlightPWA"
      action={
        flightWizardState.isAddAHotelChecked || flightWizardState.isAddACarChecked
          ? "/flexibleshopping"
          : flightConfig.form.action
      }
      onSubmit={flightWizardState.submit}
      id={flightConfig.elementId}
      data-fm={fmId}
      data-fm-title-id={fmTitleId}
    >
      {flightWizardState.isAddAHotelChecked || flightWizardState.isAddACarChecked ? (
        <FHCHiddenSearchData flightWizardState={props.wizardState.flightWizardState} />
      ) : (
        <FlightHiddenSearchData {...flightHiddenSearchDataProps} />
      )}

      <LobTitle lobState={flightWizardState} customTitle={titleFromComposition} />
      <LobSubtitle lobState={flightWizardState} customSubTitle={subTitleFromComposition} />
      {/* Each tab represents a flights sub LOB (roundtrip, one way and multicity) */}
      <UitkSpacing margin={{ blockstart: "three" }}>
        <UitkExperimentalButtonTabs
          className="flightTypeTabs"
          selectedTab={currentTab}
          onTabSelect={handleTabChange}
          tabsType={UitkTabsType.NATURAL}
          tabAnchorType={UitkTabAnchorType.BUTTON}
          noNavigationItems={halfWizard ? undefined : noNavigationItems}
          data-testid={currentTab}
        >
          <UitkTab
            name={FlightType.ROUND_TRIP}
            displayName={formatText(locKeys.flightRoundTripText)}
            targetURI={`?flightType=${FlightType.ROUND_TRIP}`}
            id={`${tabIdPrefix}${FlightType.ROUND_TRIP}`}
          >
            {currentTab === FlightType.ROUND_TRIP && (
              <>
                {!halfWizard && smallViewNavigationItems}
                <RoundtripFlight lobState={flightWizardState} flightConfig={flightConfig} {...props} />
              </>
            )}
          </UitkTab>

          <UitkTab
            name={FlightType.ONE_WAY}
            displayName={formatText(locKeys.flightOneWayText)}
            targetURI={`?flightType=${FlightType.ONE_WAY}`}
            id={`${tabIdPrefix}${FlightType.ONE_WAY}`}
          >
            {currentTab === FlightType.ONE_WAY && (
              <>
                {!halfWizard && smallViewNavigationItems}
                <OnewayFlight lobState={flightWizardState} flightConfig={flightConfig} {...props} />
              </>
            )}
          </UitkTab>

          <UitkTab
            name={FlightType.MULTI_CITY}
            displayName={formatText(locKeys.flightMultiCityText)}
            targetURI={`?flightType=${FlightType.MULTI_CITY}`}
            id={`${tabIdPrefix}${FlightType.MULTI_CITY}`}
          >
            {currentTab === FlightType.MULTI_CITY && (
              <>
                {!halfWizard && smallViewNavigationItems}
                <MulticityFlight lobState={flightWizardState} flightConfig={flightConfig} {...props} />
              </>
            )}
          </UitkTab>
        </UitkExperimentalButtonTabs>
      </UitkSpacing>
      <SubmitRow
        lobState={flightWizardState}
        rfrr={referrerId}
        extraUISTracking={extraTracking}
        globalConfig={globalWizardState.config}
      />
    </form>
  );
});
