import * as React from "react";
import { inject, observer } from "mobx-react";
import { WizardFlightPWAProps, WizardFlightPWAViewProps, WizardFlightPWAFlexModuleResult } from "./typings";
import { withRouter } from "react-router";

import { useLocalization } from "@shared-ui/localization-context";
import { RefProviderRoot } from "@shared-ui/ref-context";

import { ClassCodes } from "components/shared/PreferredClassInput/PreferredClassInput";
import { PreferredAirline } from "components/shared/PreferredAirlineInput/PreferredAirlineInput";
import { FlightConfig } from "stores/wizard/config";
import { FlightWizardState } from "stores/wizard/state";
import { FlightType } from "stores/wizard/state/typings";
import { WizardFlightPWALPView } from "./views/WizardFlightPWALP";
import { WizardFlightPWAView } from "./views/WizardFlightPWAView";
import { WizardFlightPWAViewHotwire } from "./views/WizardFlightPWAViewHotwire";
import { getOffsetDatesByDatesOption } from "src/components/utility/OffsetDate/OffsetDateUtil";

//for URL construction
const flightClassCodeMap = {
  p: "cabinclass:premium",
  b: "cabinclass:business",
  f: "cabinclass:first",
  e: "cabinclass:economy",
  default: "cabinclass:economy",
};

export enum Views {
  DEFAULT = "default",
  HOTWIRE = "hotwire",
  LANDING_PAGE = "landing-page",
  LANDING_PAGE_UX_TWEAKS = "landing-page-ux-tweaks",
}

interface ViewsToComponentMap {
  [Views.DEFAULT]: React.FC<WizardFlightPWAViewProps>;
  [Views.HOTWIRE]: React.FC<WizardFlightPWAViewProps>;
  [Views.LANDING_PAGE]: React.FC<WizardFlightPWAViewProps>;
  [Views.LANDING_PAGE_UX_TWEAKS]: React.FC<WizardFlightPWAViewProps>;
}

const WizardLPWithRefWrapper: React.FunctionComponent<WizardFlightPWAViewProps> = (props) => (
  <RefProviderRoot>
    <WizardFlightPWALPView {...props} />
  </RefProviderRoot>
);

const viewsToComponentMap: ViewsToComponentMap = {
  [Views.DEFAULT]: WizardFlightPWAView,
  [Views.HOTWIRE]: WizardFlightPWAViewHotwire,
  [Views.LANDING_PAGE]: WizardLPWithRefWrapper,
  [Views.LANDING_PAGE_UX_TWEAKS]: WizardLPWithRefWrapper,
};

const getComponentFromView = (view?: string): React.FunctionComponent<WizardFlightPWAViewProps> =>
  viewsToComponentMap[(view as keyof ViewsToComponentMap) ?? Views.DEFAULT] || viewsToComponentMap[Views.DEFAULT];

@inject("wizardState", "context", "compositionStore", "experiment", "page", "flexModuleModelStore")
@observer
export class WizardFlightPWA extends React.Component<WizardFlightPWAProps> {
  private options: WizardFlightPWAFlexModuleResult;

  public onSelectPreferredClassCode = (classCode: ClassCodes) => {
    this.props.wizardState.flightWizardState.setFlightClass(classCode);
  };

  public onSelectPreferredAirline = (preferredAirline: PreferredAirline) => {
    this.props.wizardState.flightWizardState.setFlightAirline(preferredAirline);
  };

  private updateSubLOB = (flightType: FlightType) => {
    this.props.wizardState.flightWizardState.updateSubLOBState(flightType);
  };

  private setInputsRefs = (ref: React.RefObject<HTMLInputElement>, secondRef?: React.RefObject<HTMLInputElement>) => {
    const {
      wizardState: {
        flightWizardState: { wizardInputsArray },
      },
    } = this.props;

    wizardInputsArray.push(ref);
    if (secondRef) {
      wizardInputsArray.push(secondRef);
    }
  };

  private getLocError = (isLink: boolean) => {
    const {
      wizardState: {
        flightWizardState,
        flightWizardState: { config: flightConfig },
      },
    } = this.props;

    if (!isLink) {
      return flightWizardState.numberOfErrors
        ? flightConfig.form.submitButton.locWarningsWithNumberToken!
        : flightConfig.form.submitButton.locWarningsToken!;
    }

    return flightWizardState.moreThanOneError()
      ? flightConfig.form.submitButton.locFirstWarningLinkToken
      : flightConfig.form.submitButton.locWarningLinkToken;
  };

  //Gets the URL fragment for the "main" flight; if the multi city option is selected, additional legs are added to the URL via another path
  public static getLegURLFragment(flightWizardState: FlightWizardState, flightConfig: FlightConfig): string[] {
    const legs: string[] = new Array(2);

    const rawFormatForSubmission = flightConfig.date.ISOSubmissionSupport
      ? flightConfig.date.format
      : flightWizardState.localDateFormat;

    const { formatDate } = useLocalization();

    const from = "from:";
    const origin = flightWizardState.location.origin.value;
    const comma = ",";
    const to = "to:";
    const destination = flightWizardState.location.destination.value;
    const departure = "departure:";
    const startDate = formatDate(flightWizardState.date.start, {
      raw: rawFormatForSubmission,
    });
    const departureTime = "TANYT";

    let leg1;
    leg1 = from + origin + comma + to + destination + comma + departure + startDate + departureTime;
    legs[0] = leg1;

    if (flightWizardState.subLOBState === FlightType.ROUND_TRIP) {
      let leg2;
      const endDate = formatDate(flightWizardState.date.end, {
        raw: rawFormatForSubmission,
      });
      leg2 = from + destination + comma + to + origin + comma + departure + endDate + departureTime;
      legs[1] = leg2;
    }

    return legs;
  }

  public static getFlightClassCodeForURL(flightClassCode: ClassCodes): string {
    return flightClassCodeMap[flightClassCode];
  }

  constructor(props: WizardFlightPWAProps) {
    super(props);
    const flightWizardState = this.props.wizardState.flightWizardState;
    flightWizardState.overrideConfig(() => {
      const { templateComponent, flexModuleModelStore } = this.props;
      const {
        metadata: { id },
      } = templateComponent;
      this.options = flexModuleModelStore.getModel(id) as WizardFlightPWAFlexModuleResult;
      flightWizardState.config.elementId = id;
      flightWizardState.config.enableFlightFareCalendar = this.options.enableFlightFareCalendar;
      flightWizardState.config.autoOpenFlightFareCalendar = this.options.autoOpenFlightFareCalendar;
      flightWizardState.config.heading.fixedTitle = this.options.fixedLobTitle;
      flightWizardState.config.heading.lobSubtitleBreakPoint = this.options.lobSubtitleBreakPoint;
      flightWizardState.config.heading.hideLobTitle = this.options.hideLobTitle;
      if (this.options.titleFromPageHeader) {
        flightWizardState.config.heading.differentiatedTitles = false;
      }
      if (this.options.subTitleFromPageSubHeader) {
        flightWizardState.config.heading.lobSubtitleBreakPoint = "on";
      }

      const currentSiteId = props.context?.site?.id.toString();

      if (
        flightWizardState.config.siteIdsWithoutSoftPackages.length !== 0 &&
        flightWizardState.config.siteIdsWithoutSoftPackages.includes(currentSiteId)
      ) {
        flightWizardState.config.enableAddHotel = false;
        flightWizardState.config.enableAddCar = false;
      } else {
        flightWizardState.config.enableAddHotel = this.options.enableAddHotel;
        flightWizardState.config.enableAddCar = this.options.enableAddCar;
      }

      const { defaultOffsetDates } = flightWizardState.config.date;
      const { startDateOffset, endDateOffset } = getOffsetDatesByDatesOption(this.options);

      if (defaultOffsetDates && startDateOffset !== undefined && endDateOffset !== undefined) {
        defaultOffsetDates.start = startDateOffset;
        defaultOffsetDates.end = endDateOffset;
        flightWizardState.updateFlightLegsStateDatesByConfig();
      }
    });
    flightWizardState.updateDateFromConfig();

    this.props.wizardState.flightWizardState.localDateFormat = this.props.context.localDateFormat;
  }

  public componentDidMount() {
    const {
      context: { searchContext },
      wizardState: { flightWizardState },
    } = this.props;

    if (this.options.view === Views.LANDING_PAGE || this.options.view === Views.LANDING_PAGE_UX_TWEAKS) {
      flightWizardState.updateStateFromContext(this.props.context);
    }

    flightWizardState.updateDestinationOnRegionType(
      searchContext.destination?.type,
      flightWizardState.location.destination.value
    );
  }

  public render() {
    const ViewComponent = getComponentFromView(this.options.view);

    return (
      <ViewComponent
        {...this.props}
        onSelectFlightType={this.updateSubLOB}
        onSelectPreferredClassCode={this.onSelectPreferredClassCode}
        onSelectPreferredAirline={this.onSelectPreferredAirline}
        setInputsRefs={this.setInputsRefs}
        getLocError={this.getLocError}
      />
    );
  }
}

export default withRouter(WizardFlightPWA);
