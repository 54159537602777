import * as React from "react";
import { IDialog } from "@shared-ui/dialog-context";

const CustomLayer: React.FC<{ id: string; isOpen: boolean }> = (props) => {
  const { id, children } = props;

  return (
    <div className="uitk-dialog-layer uitk-dialog-layer-responsive" id={id}>
      {children}
    </div>
  );
};

export const dialogProviderConfig: IDialog = {
  useDialogControl: (id: string, dialogQueryKey: string, isOpen: boolean, setIsOpen) => {
    const actions = {
      openDialog: () => setIsOpen(true),
      closeDialog: () => setIsOpen(false),
      toggleDialog: () => setIsOpen(!isOpen),
    };

    return {
      ComponentFactory: (props): React.ReactElement => {
        const { children } = props;

        return (
          <CustomLayer id={id} isOpen={isOpen}>
            {children}
          </CustomLayer>
        );
      },
      actions,
    };
  },
};
