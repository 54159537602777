import * as React from "react";
import { observer } from "mobx-react";

import { UitkTertiaryButton } from "@egds/react-core/button";
import {
  UitkLayoutGrid,
  UitkLayoutGridItem,
  UitkLayoutConditionalGridTrack,
  UitkLayoutConditionalGridSpan,
} from "@egds/react-core/layout-grid";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkIcon } from "@egds/react-core/icons";
import { UitkText } from "@egds/react-core/text";

import { useLocalization } from "@shared-ui/localization-context";
import { Viewport, ViewSmall, ViewMedium } from "@shared-ui/viewport-context";

import { FlightSubLOBProps } from "components/flexComponents/WizardFlightPWA/typings";
import { locKeys } from "components/flexComponents/WizardFlightPWA/components/l10n";
import { MultiCityFlightLeg } from "components/flexComponents/WizardFlightPWA/components/MultiCityFlightLeg";
import { FlightPreferences } from "components/flexComponents/WizardFlightPWA/components/FlightPreferences";

import { LobErrorSummary } from "src/components/shared/LobErrorSummary/LobErrorSummary";
import { Columns } from "components/shared/StorefrontWizard/typings";
import { LocationType } from "src/components/shared/Location/components/LocationType";

export const MulticityFlight = observer((props: FlightSubLOBProps) => {
  const [linkDisabled, setLinkDisabled] = React.useState(true);
  const {
    halfWizard,
    defaultMultipleDestination,
    defaultDestination,
    defaultMultipleOrigin,
    defaultOrigin,
    defaultStopover,
    defaultMultipleStopover,
  } = props.wizardState.globalWizardState.config;
  const { DESTINATION, ORIGIN } = LocationType;

  if ((Array.isArray(defaultMultipleDestination) || defaultDestination) && props.lobState.location[DESTINATION]) {
    props.lobState.location[DESTINATION].value = "";
  }

  if ((Array.isArray(defaultMultipleOrigin) || defaultOrigin) && props.lobState.location[ORIGIN]) {
    props.lobState.location[ORIGIN].value = "";
  }

  if (defaultStopover) {
    props.lobState.location.destination.value = defaultStopover.split(":")[0];
  }
  if (Array.isArray(defaultMultipleStopover)) {
    props.lobState.location.destination.value = defaultMultipleStopover[0].split(":")[0];
  }

  const multicityColumns: Columns = {
    fullWidth: undefined,
    half: {
      small: ["1fr"],
      medium: ["1fr"],
    },
  };

  /**
   * This hook is reached for rehydratation, here the "add a flight" link gets abled, if JS is disabled then this hook is never executed
   */
  React.useEffect(() => setLinkDisabled(false), []);

  const gridColumns: UitkLayoutConditionalGridTrack = { small: 1, medium: 12 };
  const addFlightColSpan: UitkLayoutConditionalGridSpan = { small: 1, medium: 12 };
  const multicityGridColumns: UitkLayoutConditionalGridTrack = multicityColumns[halfWizard ? "half" : "fullWidth"];

  const { errorInputRef, isMulticityFormValid, updateInvalidFormsState } = props.lobState;

  if (isMulticityFormValid) {
    updateInvalidFormsState();
  }

  const { formatText } = useLocalization();

  return (
    <UitkSpacing margin={{ blockstart: "three" }}>
      <UitkLayoutGrid columns={gridColumns} space="three">
        {!isMulticityFormValid && (
          <LobErrorSummary
            locHeadingArgs={props.lobState.moreThanOneError() && [props.lobState.numberOfErrors]}
            locHeadingToken={props.getLocError(false)!}
            locLinkToken={props.getLocError(true)}
            linkClickFocusId="multi-error"
            inputErrorRef={errorInputRef}
            colSpan={gridColumns as UitkLayoutConditionalGridSpan}
            setInputsRefs={props.setInputsRefs}
          />
        )}
        {halfWizard && <FlightPreferences {...props} />}
        <MultiCityFlightLeg
          lobState={props.lobState}
          setInputsRefs={props.setInputsRefs}
          globalConfig={props.wizardState.globalWizardState.config}
          mainGridColumns={multicityGridColumns}
        />

        <Viewport>
          <ViewSmall>
            <>
              {props.lobState.listOfAdditionalLegs.length <
                props.lobState.config.multiCityFlight.maxNumberOfAdditionalLegs && (
                <UitkLayoutGridItem colSpan={addFlightColSpan}>
                  <div>
                    <UitkTertiaryButton onClick={props.lobState.addALeg} disabled={linkDisabled}>
                      <UitkIcon name="add" />
                      {formatText(locKeys.addFlightLabel)}
                    </UitkTertiaryButton>
                  </div>
                </UitkLayoutGridItem>
              )}
            </>
          </ViewSmall>
          <ViewMedium>
            <>
              {props.lobState.listOfAdditionalLegs.length <
                props.lobState.config.multiCityFlight.maxNumberOfAdditionalLegs && (
                <UitkLayoutGridItem colSpan={addFlightColSpan}>
                  <UitkText align="right">
                    <UitkTertiaryButton onClick={props.lobState.addALeg} disabled={linkDisabled}>
                      <UitkIcon name="add" />
                      {formatText(locKeys.addFlightLabel)}
                    </UitkTertiaryButton>
                  </UitkText>
                </UitkLayoutGridItem>
              )}
            </>
          </ViewMedium>
        </Viewport>
      </UitkLayoutGrid>
    </UitkSpacing>
  );
});
