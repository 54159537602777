import * as React from "react";
import { observer } from "mobx-react";

import {
  UitkLayoutConditionalGridTrack,
  UitkLayoutGrid,
  UitkLayoutGridItem,
  UitkLayoutConditionalGridSpan,
} from "@egds/react-core/layout-grid";
import { UitkSpacing } from "@egds/react-core/spacing";

import { FlightTypeMenu } from "components/flexComponents/WizardFlightPWA/components/FlightTypeMenu";
import { FlightHiddenSearchDataHotwire } from "components/flexComponents/WizardFlightPWA/components/FlightHiddenSearchDataHotwire";
import { Dates } from "components/shared/Dates/Dates";
import { LobErrorSummary } from "components/shared/LobErrorSummary/LobErrorSummary";
import { Location } from "components/shared/Location/Location";
import { SubmitButton } from "components/shared/SubmitButton/SubmitButton";
import { OffsetGridItem } from "components/shared/OffsetGridItem/OffsetGridItem";
import { Travelers } from "components/shared/Travelers/Travelers";
import { TravelersTriggerTypes } from "components/shared/TravelersField/typings";

import { FlightType } from "stores/wizard/state/typings";
import { useHotwireFlightOverrides } from "components/flexComponents/WizardFlightPWA/utils/hotwireFlightOverrides";
import { WizardFlightPWAViewProps } from "../typings";
import { useMultiLOBWizardRegion } from "components/flexComponents/WizardPWARegions/WizardRegionHotwire/WizardRegionContextProvider/MultiLOBWizardRegionContext";
import { getFmId } from "src/stores/ExperienceTemplateStore";

export const WizardFlightPWAViewHotwire: React.FunctionComponent<WizardFlightPWAViewProps> = observer(
  ({
    onSelectFlightType,
    setInputsRefs,
    getLocError,
    flexModuleModelStore,
    templateComponent,
    wizardState: { globalWizardState, flightWizardState },
  }) => {
    useHotwireFlightOverrides(flightWizardState);
    const {
      config: {
        form: { action },
      },
      subLOBState,
      errorInputRef,
      isRoundtripFormValid,
      isOnewayFormValid,
      numberOfErrors,
      updateInvalidFormsState,
      moreThanOneError,
    } = flightWizardState;

    const isRoundtrip = subLOBState === FlightType.ROUND_TRIP;
    const isOneway = subLOBState === FlightType.ONE_WAY;

    const isFormValid = (isRoundtrip && isRoundtripFormValid) || (isOneway && isOnewayFormValid);

    if (isFormValid) {
      updateInvalidFormsState();
    }

    const { isMultiLOB } = useMultiLOBWizardRegion();

    const mainGridColumns: UitkLayoutConditionalGridTrack = { small: 1, large: 2 };

    return (
      <form
        noValidate
        className="WizardFlightPWAHotwire"
        action={action}
        onSubmit={flightWizardState.submit}
        id={flightWizardState.config.elementId}
        data-fm={getFmId(templateComponent)}
        data-fm-title-id={templateComponent.config.fmTitleId}
      >
        <FlightHiddenSearchDataHotwire lobState={flightWizardState} />
        <UitkSpacing padding={{ blockstart: isMultiLOB ? "unset" : "one", blockend: "one" }}>
          <div>
            <FlightTypeMenu onFlightTypeUpdate={onSelectFlightType} activeFlightType={subLOBState} showOneway />
          </div>
        </UitkSpacing>
        <UitkLayoutGrid columns={{ small: 1, large: isMultiLOB ? 1 : 3 }}>
          <UitkLayoutGridItem colSpan={2}>
            <UitkLayoutGrid columns={mainGridColumns} space="three">
              {!isFormValid && (
                <LobErrorSummary
                  locHeadingToken={getLocError(false)!}
                  locHeadingArgs={moreThanOneError() && [numberOfErrors]}
                  locLinkToken={getLocError(true)}
                  linkClickFocusId="flight-error"
                  inputErrorRef={errorInputRef}
                  colSpan={mainGridColumns as UitkLayoutConditionalGridSpan}
                  setInputsRefs={setInputsRefs}
                />
              )}
              <Location
                setInputsRefs={setInputsRefs}
                originField
                destinationField
                lobState={flightWizardState}
                showSwapLocationsControl={false}
                colSpan={{ small: 1, large: 2 }}
                globalConfig={globalWizardState.config}
              />
              <Dates
                lobState={flightWizardState}
                colSpan={{ small: 1, large: 1 }}
                setInputsRefs={setInputsRefs}
                singleDate={isOneway}
                singleDateSelect={isRoundtrip}
              />
              <Travelers
                lobState={flightWizardState}
                config={flightWizardState.config.travelers}
                colSpan={{ small: 1, large: 1 }}
                noRehydratationValues={flightWizardState.config.travelers.noRehydratationValues}
                triggerType={TravelersTriggerTypes.FAKE_INPUT}
              />
            </UitkLayoutGrid>
          </UitkLayoutGridItem>
        </UitkLayoutGrid>
        <UitkSpacing margin={{ blockstart: "three", blockend: "three" }}>
          <UitkLayoutGrid columns={{ small: 1, medium: 12, large: 12 }} space={isMultiLOB ? "four" : "three"}>
            {isMultiLOB && <OffsetGridItem colSpan={{ large: 4 }} />}
            <SubmitButton lobState={flightWizardState} colSpan={{ small: 1, medium: 12, large: 4 }} />
          </UitkLayoutGrid>
        </UitkSpacing>
      </form>
    );
  }
);
