import * as React from "react";
import { observer } from "mobx-react";

import { UitkLayoutGrid, UitkLayoutGridItem, UitkLayoutConditionalGridTrack } from "@egds/react-core/layout-grid";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { UitkTertiaryButton } from "@egds/react-core/button";
import { UitkText } from "@egds/react-core/text";
import { UitkSpacing } from "@egds/react-core/spacing";
import { liveAnnounce, Level } from "@egds/react-core/utils";
import { useLocalization } from "@shared-ui/localization-context";

import { MultiCityFlightLegProps } from "components/flexComponents/WizardFlightPWA/typings";
import { Location } from "components/shared/Location/Location";
import { Dates } from "components/shared/Dates/Dates";
import { locKeys } from "components/flexComponents/WizardFlightPWA/components/l10n";

export const MultiCityFlightLeg = observer((props: MultiCityFlightLegProps) => {
  const defaultMainGridColumns: UitkLayoutConditionalGridTrack = { small: ["1fr"], medium: ["9fr", "3fr"] };

  const { formatText } = useLocalization();

  const removeALeg = (index: number) => {
    const removedAnnounceText = formatText(locKeys.multiCityFlightRemovedLiveAnnouce, index + 2);
    props.lobState.removeALeg(index);
    setTimeout(() => {
      liveAnnounce(removedAnnounceText, Level.POLITE);
    });
  };

  const mainGridColumns = props.mainGridColumns || defaultMainGridColumns;
  const minNumberOfLegs = props.lobState.config.multiCityFlight.minNumberOfLegs;
  const singleStopover = props.globalConfig?.defaultStopover;
  const multipleStopover = props.globalConfig?.defaultMultipleStopover;
  const isUsingUXTweaks = props.isUsingUXTweaks;

  return (
    <UitkLayoutGridItem colSpan="all">
      <div>
        <div role="group" aria-label={formatText(locKeys.multiCityFlightLegLabel, 1)} key="multi-city-leg-1">
          <UitkLayoutFlex>
            <UitkLayoutFlexItem>
              <UitkText size={300} aria-hidden weight="bold">
                {formatText(locKeys.multiCityFlightLegLabel, 1)}
              </UitkText>
            </UitkLayoutFlexItem>
          </UitkLayoutFlex>
          <UitkSpacing margin={{ blockstart: "three" }}>
            <UitkLayoutGrid
              columns={mainGridColumns}
              space="three"
              data-testid={isUsingUXTweaks ? "inputGrid_uxtweaks" : undefined}
            >
              <Location
                setInputsRefs={props.setInputsRefs}
                originField
                destinationField
                lobState={props.lobState}
                showSwapLocationsControl={props.showSwapLocationsControl}
                globalConfig={props.globalConfig}
                stopoverDestination={singleStopover}
                stopoverMultipleDestination={multipleStopover}
                originIcon={isUsingUXTweaks ? "flight_takeoff" : undefined}
                destinationIcon={isUsingUXTweaks ? "flight_land" : undefined}
              />
              <Dates singleDate lobState={props.lobState} setInputsRefs={props.setInputsRefs} />
            </UitkLayoutGrid>
          </UitkSpacing>
        </div>
        {props.lobState.listOfAdditionalLegs.map((leg, index) => (
          <UitkSpacing
            padding={{ small: { blockstart: "unset" }, medium: { blockstart: "three" } }}
            key={`multi-city-leg-${index + 2}`}
          >
            <div role="group" aria-label={formatText(locKeys.multiCityFlightLegLabel, index + 2)}>
              <UitkSpacing margin={{ blockstart: index + 1 > 0 ? "three" : "unset" }}>
                <UitkLayoutFlex justifyContent="space-between">
                  <UitkLayoutFlexItem>
                    <div>
                      <UitkSpacing margin={{ small: { blockstart: "six" }, medium: "unset" }}>
                        <UitkText size={300} aria-hidden weight="bold">
                          {formatText(locKeys.multiCityFlightLegLabel, index + 2)}
                        </UitkText>
                      </UitkSpacing>
                    </div>
                  </UitkLayoutFlexItem>
                  {index + 2 > minNumberOfLegs && (
                    <UitkLayoutFlexItem>
                      <div>
                        <UitkSpacing margin={{ small: { blockstart: "four" } }}>
                          <div>
                            <UitkTertiaryButton
                              onClick={removeALeg.bind(this, index)}
                              id="removeFlightLeg"
                              aria-label={formatText(locKeys.multiCityFlightRemoveA11y, index + 2)}
                            >
                              {formatText(locKeys.multiCityFlightRemoveLabel)}
                            </UitkTertiaryButton>
                          </div>
                        </UitkSpacing>
                      </div>
                    </UitkLayoutFlexItem>
                  )}
                </UitkLayoutFlex>
              </UitkSpacing>
              <UitkSpacing margin={{ blockstart: "three" }}>
                <UitkLayoutGrid columns={mainGridColumns} space="three">
                  <Location
                    originField
                    destinationField
                    lobState={props.lobState}
                    multiLegIndex={index}
                    showSwapLocationsControl={props.showSwapLocationsControl}
                    setInputsRefs={props.setInputsRefs}
                    globalConfig={props.globalConfig}
                    stopoverOrigin={singleStopover}
                    stopoverMultipleOrigin={multipleStopover}
                    originIcon={isUsingUXTweaks ? "flight_takeoff" : undefined}
                    destinationIcon={isUsingUXTweaks ? "flight_land" : undefined}
                  />
                  <Dates singleDate lobState={props.lobState} multiLegIndex={index} />
                  <input
                    type="hidden"
                    name={`leg${index + 2}`} // input name pattern for flight: leg1,leg2,leg...
                    value={props.lobState.multiCityFlightLegURL[index] || ""} // to maintain input as controlled component before value is defined
                    data-testid="multicity-url"
                  />
                </UitkLayoutGrid>
              </UitkSpacing>
            </div>
          </UitkSpacing>
        ))}
      </div>
    </UitkLayoutGridItem>
  );
});
