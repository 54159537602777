import * as React from "react";
import { observer } from "mobx-react";
import { UitkCheckbox } from "@egds/react-core/checkbox";
import { FlightAddNonStopProps } from "components/flexComponents/WizardFlightPWA/typings";
import { useLocalization } from "@shared-ui/localization-context";
import { UitkLayoutFlexItem } from "@egds/react-core/layout-flex";

export const FlightNonStopCheckbox = observer((props: FlightAddNonStopProps) => {
  const { formatText } = useLocalization();
  const { isNonStopFlightChecked, toggleNonStopFlightCheckbox } = props.flightWizardState;

  return (
    <UitkLayoutFlexItem>
      <UitkCheckbox
        checked={isNonStopFlightChecked}
        id="nonstop-flight-checkbox"
        onChange={toggleNonStopFlightCheckbox}
      >
        {formatText("wizard.package.directFlight.label")}
      </UitkCheckbox>
    </UitkLayoutFlexItem>
  );
});
