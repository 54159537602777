import * as React from "react";
import { observer } from "mobx-react";
import { locKeys } from "components/flexComponents/WizardFlightPWA/components/l10n";
import { UitkCheckbox } from "@egds/react-core/checkbox";
import { FlightAddCarProps } from "components/flexComponents/WizardFlightPWA/typings";
import { useLocalization } from "@shared-ui/localization-context";
import { UitkLayoutFlexItem } from "@egds/react-core/layout-flex";

export const FlightAddCarCheckbox = observer((props: FlightAddCarProps) => {
  const { formatText } = useLocalization();

  return (
    <UitkLayoutFlexItem>
      <UitkCheckbox
        checked={props.flightWizardState.isAddACarChecked}
        id="add-car-checkbox"
        onChange={props.flightWizardState.toggleAddCarCheckbox}
      >
        {formatText(locKeys.addCarLabel)}
      </UitkCheckbox>
    </UitkLayoutFlexItem>
  );
});
