import * as React from "react";

import {
  RoundtripFlight,
  RoundtripFlightUXTweaks,
  StickyRoundtripFlight,
  StickyRoundtripFlightUXTweaks,
} from "src/components/flexComponents/WizardFlightPWA/components";

import type { FlightConfig } from "stores/wizard/config";
import type { FlightWizardState } from "stores/wizard/state";
import type {
  FlightHiddenSearchDataProps,
  WizardFlightPWAViewProps,
  WizardFlightPWAFlexModuleResult,
} from "../typings";

interface RoundtripProps {
  flightConfig: FlightConfig;
  flightWizardState: FlightWizardState;
  formProps: WizardFlightPWAViewProps & {
    flightHiddenSearchDataProps: FlightHiddenSearchDataProps;
    options: WizardFlightPWAFlexModuleResult;
  };
  isInitialMount: React.MutableRefObject<boolean>;
  isSticky: boolean;
  isUsingUXTweaks: boolean;
}

export const Roundtrip: React.FC<RoundtripProps> = ({
  flightConfig,
  flightWizardState,
  formProps,
  isInitialMount,
  isSticky,
  isUsingUXTweaks,
}) => {
  if (isUsingUXTweaks) {
    if (isSticky) {
      return <StickyRoundtripFlightUXTweaks lobState={flightWizardState} flightConfig={flightConfig} {...formProps} />;
    }

    return (
      <RoundtripFlightUXTweaks
        lobState={flightWizardState}
        autoOpenFareCalendar={flightWizardState.config.autoOpenFlightFareCalendar && isInitialMount.current}
        flightConfig={flightConfig}
        {...formProps}
      />
    );
  }

  if (isSticky) {
    return <StickyRoundtripFlight lobState={flightWizardState} flightConfig={flightConfig} {...formProps} />;
  }

  return (
    <RoundtripFlight
      lobState={flightWizardState}
      autoOpenFareCalendar={flightWizardState.config.autoOpenFlightFareCalendar && isInitialMount.current}
      flightConfig={flightConfig}
      {...formProps}
    />
  );
};
