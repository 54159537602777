import * as React from "react";
import { observer } from "mobx-react";

import { FlightType } from "stores/wizard/state/typings";
import { FlightWizardState } from "stores/wizard/state";

export interface HotwireFlightHiddenSearchDataProps {
  lobState: FlightWizardState;
}

interface SearchType {
  [FlightType.ROUND_TRIP]: string;
  [FlightType.ONE_WAY]: string;
}

const SEARCH_TYPE: SearchType = {
  [FlightType.ROUND_TRIP]: "2002",
  [FlightType.ONE_WAY]: "2001",
};

/**
 * Renders hidden inputs required to search via the Hotwire Phoenix /air/dispatch.jsp route.
 */
export const FlightHiddenSearchDataHotwire = observer(
  ({
    lobState: {
      subLOBState,
      location: {
        origin: {
          metaData: { ttla: originAirportCode },
        },
        destination: {
          metaData: { ttla: destinationAirportCode },
        },
      },
    },
  }: HotwireFlightHiddenSearchDataProps) => (
    <>
      <input
        type="hidden"
        name="searchType"
        data-testid="searchType"
        value={SEARCH_TYPE[subLOBState as keyof SearchType]}
      />
      <input type="hidden" name="origCity" data-testid="origCity" value={originAirportCode} />
      <input type="hidden" name="destinationCity" data-testid="destinationCity" value={destinationAirportCode} />
      <input type="hidden" name="inputId" data-testid="inputId" value="index" />
      <input type="hidden" name="isMultiAirport" data-testid="isMultiAirport" value="true" />
      <input type="hidden" name="addMoreFlightsSelected" data-testid="addMoreFlightsSelected" value="false" />
    </>
  )
);
