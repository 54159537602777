import * as React from "react";
import { observer } from "mobx-react";

import { UitkLayoutFlex } from "@egds/react-core/layout-flex";
import {
  UitkLayoutGrid,
  UitkLayoutGridItem,
  UitkLayoutConditionalGridSpan,
  UitkLayoutConditionalGridTrack,
} from "@egds/react-core/layout-grid";
import { UitkSpacing } from "@egds/react-core/spacing";

import { LobErrorSummary } from "components/shared/LobErrorSummary/LobErrorSummary";
import { Location } from "components/shared/Location/Location";
import { Dates } from "components/shared/Dates/Dates";
import { FlightSubLOBProps } from "components/flexComponents/WizardFlightPWA/typings";
import { FlightAddHotelCheckbox } from "components/flexComponents/WizardFlightPWA/components/FlightAddHotelCheckbox";
import { FlightAddCarCheckbox } from "components/flexComponents/WizardFlightPWA/components/FlightAddCarCheckbox";
import { FlightPreferences } from "components/flexComponents/WizardFlightPWA/components/FlightPreferences";

import { FlightsFareCalendar } from "./FlightsFareCalendar";
import { Columns } from "components/shared/StorefrontWizard/typings";
import { FlightNonStopCheckbox } from "components/flexComponents/WizardFlightPWA/components/FlightNonStopCheckbox";
import { LocationType } from "src/components/shared/Location/components/LocationType";

export const RoundtripFlight = observer((props: FlightSubLOBProps) => {
  const { halfWizard, defaultMultipleStopover, defaultStopover } = props.wizardState.globalWizardState.config;
  const { DESTINATION } = LocationType;

  if ((defaultMultipleStopover || defaultStopover) && props.lobState.location[DESTINATION]) {
    props.lobState.location[DESTINATION].value = "";
  }

  const locationColumns: Columns = {
    fullWidth: { small: 4, medium: 6, large: 8 },
    half: {
      small: 12,
      medium: 12,
      large: 12,
    },
  };

  const datesColumns: Columns = {
    fullWidth: { small: 4, medium: 6, large: 4 },
    half: {
      small: 12,
      medium: 12,
      large: 12,
    },
  };

  const softPackagesColSpan = halfWizard ? 12 : "all";

  const mainGridColumns: UitkLayoutConditionalGridTrack = { small: 4, medium: 6, large: 12 };
  const locationColSpan: UitkLayoutConditionalGridSpan = locationColumns[halfWizard ? "half" : "fullWidth"];
  const { enableAddCar, enableAddHotel, enableNonStopFlight, enableFlightFareCalendar } = props.lobState.config;
  const { isAddAHotelChecked } = props.lobState;

  const {
    errorInputRef,
    isRoundtripFormValid,
    numberOfErrors,
    updateInvalidFormsState,
    moreThanOneError,
  } = props.lobState;
  const datesColSpan: UitkLayoutConditionalGridSpan = datesColumns[halfWizard ? "half" : "fullWidth"];
  if (isRoundtripFormValid) {
    updateInvalidFormsState();
  }

  return (
    <UitkSpacing padding={{ block: "three" }}>
      <UitkLayoutGrid columns={mainGridColumns} space="three">
        {!isRoundtripFormValid && (
          <LobErrorSummary
            locHeadingToken={props.getLocError(false)!}
            locHeadingArgs={moreThanOneError() && [numberOfErrors]}
            locLinkToken={props.getLocError(true)}
            linkClickFocusId="id-roundtrip-error"
            inputErrorRef={errorInputRef}
            colSpan={mainGridColumns as UitkLayoutConditionalGridSpan}
            setInputsRefs={props.setInputsRefs}
          />
        )}
        {halfWizard && <FlightPreferences {...props} />}
        <Location
          setInputsRefs={props.setInputsRefs}
          originField
          destinationField
          lobState={props.lobState}
          showSwapLocationsControl
          colSpan={locationColSpan}
          globalConfig={props.wizardState.globalWizardState.config}
        />
        {enableFlightFareCalendar && (
          <FlightsFareCalendar
            singleDate={false}
            lobState={props.lobState}
            colSpan={datesColSpan}
            singleDateSelect
            setInputsRefs={props.setInputsRefs}
            autoOpen={props.autoOpenFareCalendar}
          />
        )}
        {!enableFlightFareCalendar && (
          <Dates
            lobState={props.lobState}
            colSpan={datesColSpan}
            singleDateSelect
            setInputsRefs={props.setInputsRefs}
          />
        )}
        {(enableAddHotel || enableAddCar || enableNonStopFlight) && (
          <UitkLayoutGridItem colSpan={softPackagesColSpan}>
            <UitkSpacing padding={{ blockstart: "two" }}>
              <UitkLayoutFlex space="three">
                {enableAddHotel && <FlightAddHotelCheckbox flightWizardState={props.lobState} />}
                {enableAddCar && <FlightAddCarCheckbox flightWizardState={props.lobState} />}
                {enableNonStopFlight && <FlightNonStopCheckbox flightWizardState={props.lobState} />}
              </UitkLayoutFlex>
            </UitkSpacing>
          </UitkLayoutGridItem>
        )}
        {enableAddHotel && isAddAHotelChecked && (
          <Dates lobState={props.lobState} isPartialStay colSpan={datesColSpan} setInputsRefs={props.setInputsRefs} />
        )}
      </UitkLayoutGrid>
    </UitkSpacing>
  );
});
