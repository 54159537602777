import * as React from "react";
import { observer } from "mobx-react";

import {
  UitkLayoutGrid,
  UitkLayoutConditionalGridSpan,
  UitkLayoutConditionalGridTrack,
} from "@egds/react-core/layout-grid";
import { UitkSpacing } from "@egds/react-core/spacing";

import { FlightsFareCalendar } from "components/flexComponents/WizardFlightPWA/components";
import { FlightSubLOBProps } from "components/flexComponents/WizardFlightPWA/typings";

import { Dates } from "components/shared/Dates/Dates";
import { LobErrorSummary } from "components/shared/LobErrorSummary/LobErrorSummary";
import { Location } from "components/shared/Location/Location";
import { getShoppingGlobalMicroMessage } from "components/shared/StorefrontWizard/SubmitRow/UISShoppingGlobal";
import { SubmitButton } from "components/shared/SubmitButton/SubmitButton";

export const StickyRoundtripFlightUXTweaks = observer((props: FlightSubLOBProps) => {
  const { flightConfig, getLocError, lobState, setInputsRefs } = props;
  const uisShoppingGlobal = getShoppingGlobalMicroMessage(lobState);
  const { errorInputRef, isRoundtripFormValid, numberOfErrors, updateInvalidFormsState, moreThanOneError } = lobState;
  const { enableFlightFareCalendar } = lobState.config;

  if (isRoundtripFormValid) {
    updateInvalidFormsState();
  }

  const mainGridColumns: UitkLayoutConditionalGridTrack = { small: 4, medium: 6, large: 12 };

  const locationColSpan: UitkLayoutConditionalGridSpan = { small: 4, medium: 6, large: 6 };
  const datesColSpan: UitkLayoutConditionalGridSpan = { small: 4, medium: 4, large: 4 };
  const submitColSpan: UitkLayoutConditionalGridSpan = { small: 4, medium: 2, large: 2 };

  return (
    <UitkSpacing margin={{ blockstart: "two" }}>
      <UitkLayoutGrid columns={mainGridColumns} space="three">
        {!isRoundtripFormValid && (
          <LobErrorSummary
            locHeadingToken={getLocError(false)!}
            locHeadingArgs={moreThanOneError() && [numberOfErrors]}
            locLinkToken={getLocError(true)}
            linkClickFocusId="id-roundtrip-error"
            inputErrorRef={errorInputRef}
            colSpan={mainGridColumns as UitkLayoutConditionalGridSpan}
            setInputsRefs={setInputsRefs}
          />
        )}
        <Location
          setInputsRefs={setInputsRefs}
          originField
          destinationField
          lobState={lobState}
          showSwapLocationsControl
          colSpan={locationColSpan}
          originIcon="flight_takeoff"
          destinationIcon="flight_land"
        />
        {enableFlightFareCalendar ? (
          <FlightsFareCalendar
            singleDate={false}
            lobState={lobState}
            colSpan={datesColSpan}
            singleDateSelect
            setInputsRefs={setInputsRefs}
          />
        ) : (
          <Dates lobState={lobState} colSpan={datesColSpan} singleDateSelect setInputsRefs={setInputsRefs} />
        )}
        <SubmitButton
          lobState={lobState}
          colSpan={submitColSpan}
          rfrr={flightConfig.referrerId}
          uisPrimeMicroMessages={uisShoppingGlobal}
          iconName="arrow_forward"
        />
      </UitkLayoutGrid>
    </UitkSpacing>
  );
});
