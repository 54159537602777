import * as React from "react";
import { PreferredAirline } from "src/components/shared/PreferredAirlineInput/PreferredAirlineInput";
import { ClassCodes } from "src/components/shared/PreferredClassInput/PreferredClassInput";
import { ClientWizardStateStore } from "src/stores/wizard/ClientWizardStateStore";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { PreferredClassInput } from "components/shared/PreferredClassInput/PreferredClassInput";
import { PreferredAirlineInput } from "components/shared/PreferredAirlineInput/PreferredAirlineInput";
import { TravelersTriggerTypes } from "components/shared/TravelersField/typings";
import { UitkLayoutConditionalGridSpan } from "@egds/react-core/layout-grid";
import { Columns } from "components/shared/StorefrontWizard/typings";
import { Travelers } from "components/shared/Travelers/Travelers";

export interface NavigationItemsProps extends PreferredClassNavItem, PreferredAirlineNavItem {
  wizardState: ClientWizardStateStore;
}

export interface PreferredClassNavItem {
  onSelectPreferredClassCode: (classCode: ClassCodes) => void;
}

export interface PreferredAirlineNavItem {
  onSelectPreferredAirline: (flightAirline: PreferredAirline) => void;
}

export const NavigationItems = ({
  wizardState,
  onSelectPreferredClassCode,
  onSelectPreferredAirline,
}: NavigationItemsProps) => {
  const travelersColumns: Columns = {
    fullWidth: { small: 2, medium: 2, large: 2 },
    half: {
      small: 2,
      large: 12,
    },
  };
  const preferredClassColumn: Columns = {
    fullWidth: { small: 3, medium: 2, large: 2 },
    half: {
      small: 1,
      large: 4,
    },
  };

  const {
    globalWizardState,
    flightWizardState,
    flightWizardState: { config: flightConfig },
  } = wizardState;
  const { isAddAHotelChecked, isAddACarChecked } = flightWizardState;

  const travelerConfig =
    flightConfig.enableAddHotel && isAddAHotelChecked
      ? flightConfig.travelersWithHotel
      : flightConfig.enableAddCar && isAddACarChecked
      ? flightConfig.travelersWithCar
      : flightConfig.travelers;

  /**
   * Value used if no rehydratation(JS disabled)
   */
  const { noRehydratationValues } = flightConfig.travelers;

  const { halfWizard } = globalWizardState.config;
  const travelersColSpan: UitkLayoutConditionalGridSpan = travelersColumns[halfWizard ? "half" : "fullWidth"];
  const preferredClassColSpan: UitkLayoutConditionalGridSpan = preferredClassColumn[halfWizard ? "half" : "fullWidth"];

  return (
    <UitkLayoutFlex alignItems="center" space="six">
      <UitkLayoutFlexItem>
        <div>
          <Travelers
            lobState={flightWizardState}
            config={travelerConfig}
            colSpan={travelersColSpan}
            noRehydratationValues={noRehydratationValues}
            triggerType={TravelersTriggerTypes.LINK_BUTTON}
          />
        </div>
      </UitkLayoutFlexItem>
      <UitkLayoutFlexItem>
        <PreferredClassInput
          colSpan={preferredClassColSpan}
          onSelectPreferredClassCode={onSelectPreferredClassCode}
          wizardState={wizardState}
        />
      </UitkLayoutFlexItem>
      <UitkLayoutFlexItem>
        <PreferredAirlineInput onSelectPreferredAirline={onSelectPreferredAirline} wizardState={wizardState} />
      </UitkLayoutFlexItem>
    </UitkLayoutFlex>
  );
};
