import * as React from "react";
import { FlightHiddenSearchDataProps } from "../typings";
import { observer } from "mobx-react";
import { FlightType } from "src/stores/wizard/state/typings";

export const FlightHiddenSearchData = observer(
  ({
    flightType,
    flightAirline,
    mode,
    trip,
    leg1,
    leg2,
    flightClass,
    maxhops,
    queryParams,
  }: FlightHiddenSearchDataProps) => {
    const tripType = trip === FlightType.MULTI_CITY ? "multi" : trip;
    const flightOptions = flightAirline ? `${flightClass},carrier:${flightAirline.value}` : `${flightClass}`;
    const hasAirCreditIntent = queryParams?.get("airCreditIntent") === "true";

    return (
      <div>
        <input type="hidden" name="flight-type" data-testid="flight-type" value={flightType} />
        <input type="hidden" name="mode" data-testid="flight-mode" value={mode} />
        <input type="hidden" name="trip" data-testid="flight-triptype" value={tripType} />
        <input type="hidden" name="leg1" data-testid="flight-leg1" value={leg1} />
        <input type="hidden" name="options" data-testid="flight-options" value={`${flightOptions}${maxhops || ""}`} />
        {leg2 && <input type="hidden" name="leg2" data-testid="flight-leg2" value={leg2} />}
        {hasAirCreditIntent && (
          <input type="hidden" name="airCreditIntent" data-testid="flight-air-credit-intent" value="true" />
        )}
      </div>
    );
  }
);
