import { FlightSubLOBProps } from "components/flexComponents/WizardFlightPWA/typings";
import * as React from "react";
import { observer } from "mobx-react";
import { Location } from "src/components/shared/Location/Location";
import { Dates } from "src/components/shared/Dates/Dates";
import { FlightsFareCalendar } from "./FlightsFareCalendar";
import {
  UitkLayoutGrid,
  UitkLayoutConditionalGridSpan,
  UitkLayoutConditionalGridTrack,
} from "@egds/react-core/layout-grid";
import { UitkSpacing } from "@egds/react-core/spacing";
import { LobErrorSummary } from "src/components/shared/LobErrorSummary/LobErrorSummary";
import { SubmitButton } from "src/components/shared/SubmitButton/SubmitButton";
import { getShoppingGlobalMicroMessage } from "src/components/shared/StorefrontWizard/SubmitRow/UISShoppingGlobal";

export const StickyOnewayFlight = observer((props: FlightSubLOBProps) => {
  const mainGridColumns: UitkLayoutConditionalGridTrack = { small: 2, medium: 12 };
  const locationColSpan: UitkLayoutConditionalGridSpan = { small: 2, medium: 6 };
  const datesColSpan: UitkLayoutConditionalGridSpan = { small: 2, medium: 4 };
  const submitColSpan: UitkLayoutConditionalGridSpan = { small: 4, medium: 2 };
  const { enableFlightFareCalendar } = props.lobState.config;
  const uisShoppingGlobal = getShoppingGlobalMicroMessage(props.lobState);
  const {
    errorInputRef,
    isOnewayFormValid,
    numberOfErrors,
    moreThanOneError,
    updateInvalidFormsState,
  } = props.lobState;

  if (isOnewayFormValid) {
    updateInvalidFormsState();
  }

  return (
    <UitkSpacing margin={{ blockstart: "two" }}>
      <UitkLayoutGrid columns={mainGridColumns} space="three">
        {!isOnewayFormValid && (
          <LobErrorSummary
            locHeadingToken={props.getLocError(false)!}
            locHeadingArgs={moreThanOneError() && [numberOfErrors]}
            locLinkToken={props.getLocError(true)}
            linkClickFocusId="roundtrip-error"
            inputErrorRef={errorInputRef}
            colSpan={mainGridColumns as UitkLayoutConditionalGridSpan}
            setInputsRefs={props.setInputsRefs}
          />
        )}
        <Location
          setInputsRefs={props.setInputsRefs}
          originField
          destinationField
          lobState={props.lobState}
          showSwapLocationsControl
          colSpan={locationColSpan}
        />
        {enableFlightFareCalendar && (
          <FlightsFareCalendar
            singleDate
            lobState={props.lobState}
            colSpan={datesColSpan}
            setInputsRefs={props.setInputsRefs}
          />
        )}
        {!enableFlightFareCalendar && (
          <Dates singleDate lobState={props.lobState} colSpan={datesColSpan} setInputsRefs={props.setInputsRefs} />
        )}
        <SubmitButton
          lobState={props.lobState}
          colSpan={submitColSpan}
          rfrr={props.flightConfig.referrerId}
          uisPrimeMicroMessages={uisShoppingGlobal}
        />
      </UitkLayoutGrid>
    </UitkSpacing>
  );
});
