import * as React from "react";
import { FHCHiddenSearchDataProps } from "../typings";

export const FHCHiddenSearchData = (props: FHCHiddenSearchDataProps) => {
  const flightPackageType = props.flightWizardState.flightPackageType;

  return (
    <div>
      <input type="hidden" name="packageType" data-testid="package-type" value={flightPackageType} />
      <input
        type="hidden"
        name="ftla"
        data-testid="package-origin-ttla"
        value={props.flightWizardState.location.origin.metaData.ttla}
      />
      <input
        type="hidden"
        name="ttla"
        data-testid="package-destination-ttla"
        value={props.flightWizardState.location.destination.metaData.ttla}
      />
      {flightPackageType !== "fc" && <input type="hidden" name="isPartialStay" data-testid="partial-stay" value="1" />}
      <input
        type="hidden"
        name="tripType"
        data-testid="package-trip-type"
        value={props.flightWizardState.flightSoftPackageTripType}
      />
      <input
        type="hidden"
        name="destination"
        data-testid="package-destination-value"
        value={props.flightWizardState.location.destination.value}
      />
      <input
        type="hidden"
        name="destinationId"
        data-testid="package-destination-id"
        value={props.flightWizardState.location.destination.metaData.regionId}
      />
      <input
        type="hidden"
        name="originId"
        data-testid="package-origin-id"
        value={props.flightWizardState.location.origin.metaData.regionId}
      />
    </div>
  );
};
