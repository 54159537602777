import * as React from "react";

import { MulticityFlight, MulticityFlightUXTweaks } from "src/components/flexComponents/WizardFlightPWA/components";

import type { FlightConfig } from "stores/wizard/config";
import type { FlightWizardState } from "stores/wizard/state";
import type {
  FlightHiddenSearchDataProps,
  WizardFlightPWAViewProps,
  WizardFlightPWAFlexModuleResult,
} from "../typings";

interface MulticityProps {
  flightConfig: FlightConfig;
  flightWizardState: FlightWizardState;
  formProps: WizardFlightPWAViewProps & {
    flightHiddenSearchDataProps: FlightHiddenSearchDataProps;
    options: WizardFlightPWAFlexModuleResult;
  };
  isUsingUXTweaks: boolean;
}

export const Multicity: React.FC<MulticityProps> = ({
  flightConfig,
  flightWizardState,
  formProps,
  isUsingUXTweaks,
}) => {
  if (isUsingUXTweaks) {
    return <MulticityFlightUXTweaks lobState={flightWizardState} flightConfig={flightConfig} {...formProps} />;
  }

  return <MulticityFlight lobState={flightWizardState} flightConfig={flightConfig} {...formProps} />;
};
